<template>
  <main>
    <div class="horiz-container">
      <div class="page-header">
        <h1 class="page-title">Уведомления</h1>
        <div class="page-toolbar"><BaseButton v-if="notifications?.length && notifications.some(n => !n.is_read)" color-primary @click="markAllRead">Прочитать все</BaseButton></div>
      </div>
      <div v-if="!notifications?.length" class="no-items">
        <div class="title">У вас нет уведомлений</div>
      </div>
      <div v-else class="notifications-list" :class="{ pending }">
        <Notification
          v-for="(notification, i) in notifications"
          :key="notification.id"
          :notification="notification"
          @update:notification="notifications[i] = $event"
        />
      </div>
      <BasePagination class="mt-5" :pagination="pagination" />
    </div>
  </main>
</template>

<script setup>
import BasePagination from '@/components/common/BasePagination'
import usePagination from '~/composables/usePagination'
import { getNotifications, readAllNotifications } from '@/api/notifications'

useHead({
  title: 'Уведомления'
})

const props = defineProps(['NotifyCount'])

const perPage = ref(10)
const router = useRouter()
const user = inject('user')

const {
  items: notifications,
  pagination,
  loading: pending,
  load
} = await usePagination(getNotifications, perPage)

const markAllRead = async () => {
  await readAllNotifications()
  await load()
}
</script>

<style lang="scss">
.clickable {
  cursor: pointer;
}
.notifications-text {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;

  @media (max-width: 920px) {
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }
}
.notifications-text img {
  display: block;
  width: 68px;
  height: 68px;
}
.notification-actions {
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 920px) {
    > * {
      flex: 1;
    }
  }
}
.page-header {
  justify-content: space-between;
}
.notification-title {
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  position: relative;
  margin-bottom: 8px;
  color: var(--color-elements-primary);
}
.notification-title--unread .notification-title:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: translateY(-4px);
  margin-right: 9px;
  border-radius: 50%;
  background-color: var(--color-accent);
}
.notifications-list {
  width: 100%;
}
.notifications-text {
  width: 100%;
  b {
    font-weight: inherit;
    color: var(--color-primary);
  }
}
.notifications-time {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--color-elements-secondary);
}
.notifications-read {
  width: 50%;
  text-align: right;
}
.attrs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-top: 12px;
}
.opacity-50 {
  opacity: 50%;
}
.notifications-list-item {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  border-radius: 12px;
  padding: 32px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  transition: .2s background-color;
  position: relative;
  color: var(--color-elements-secondary);
  &:hover {
    background-color: var(--color-bg-secondary);
  }
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 32px;
    right: 32px;
    height: 1px;
    background-color: var(--color-separator);
  }
}
.notifications-read button {
  font-size: 18px;
  text-transform: uppercase;
  border-bottom: 1px solid var(--color-gray-600);
}

hr {
  height: 1px;
  width: 100%;
  border-color: var(--color-gray-300);
  margin-top: 10px;
  margin-bottom: 10px;
}

.trimmed {
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
