<template>
  <div class="notifications-list-item" :class="[!notification.is_read ? 'notification-title--unread' : '', notification.meta?.mentor_id || notification.meta?.achievement_id || !notification.is_read ? 'clickable': '']" @click="markRead(notification)">
    <div class="notifications-text">
      <div>
        <h3 class="notification-title" v-html="notification.title"></h3>
        <span v-html="notification.message"></span>
        <div class="attrs">
          <div class="notifications-time">
            <ClientOnly>{{ (new Date(notification.date)).toLocaleString() }}</ClientOnly>
          </div>
        </div>
      </div>
      <div v-if="notification.meta?.icon_url">
        <img :src="notification.meta.icon_url" alt="">
      </div>
      <div v-if="notification.type === 'project-vacancy-request'" class="notification-actions" :class="{ pending }">
        <template v-if="notification.meta?.response_status === 'request'">
          <BaseButton outlined @click.stop="declineRequest">Отклонить</BaseButton>
          <BaseButton color-primary @click.stop="acceptRequest">Отправить</BaseButton>
        </template>
        <BaseButton v-else-if="notification.meta?.response_status === 'specialist-declined'" color-danger done><IconX class="icon" /><span>Отклонено</span></BaseButton>
        <BaseButton v-else color-primary done><IconCheck class="icon" /><span>Отправлено</span></BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { IconCheck, IconX } from '@tabler/icons-vue'
import { acceptNotification, declineNotification, readNotification } from '~/api/notifications'

const props = defineProps({
  notification: Object
})
const emit = defineEmits(['update:notification'])

const router = useRouter()

const notification = computed({
  get: () => props.notification,
  set: value => emit('update:notification', value)
})

const markRead = async notification => {
  if (!notification.is_read) {
    await readNotification(notification.id)
    notification.is_read = true
  }

  if (notification.meta?.mentor_id) {
    await router.push({ name: 'mentor-lessons', params: { id: notification.meta.mentor_id } })
  }

  if (notification.meta?.project_id) {
    await router.push({ name: ['project-vacancy-response', 'project-vacancy-request-accepted'].includes(notification.type) ? 'project-responses' : 'project-view', params: { id: notification.meta?.project_id } })
  }
}

const pending = ref(false)
async function resolveRequest (handler) {
  pending.value = true
  try {
    notification.value.meta = (await handler(notification.value.id)).meta
    if (!notification.value.is_read) {
      readNotification(notification.value.id)
      notification.value.is_read = true
    }
  } finally {
    pending.value = false
  }
}
const declineRequest = () => resolveRequest(declineNotification)
const acceptRequest = () => resolveRequest(acceptNotification)
</script>

<style scoped lang="scss">

</style>
